import React from 'react'
import { PageHeader as AntdPageHeader } from 'antd'

function PageHeader(props) {
  const { back, title, className, style = {} } = props
  return (
    <AntdPageHeader
      className={`header${className ? ` ${className}` : ''}`}
      onBack={() =>
        back
          ? window.location.replace(
              `${decodeURIComponent(back)}&status=USER_CANCEL`
            )
          : window.history.back()
      }
      title={title}
      style={{
        // backgroundColor: '#0D70B3',
        padding: '0.5em',
        width: '100%',
        ...style
      }}
    />
  )
}

export default PageHeader
