import React, { useState, useEffect, useRef } from 'react'
import { message as Message } from 'antd'
import { parse, stringify } from 'qs'
import PageHeader from '@/components/PageHeader'

import { fetchOrderDetail } from '@/Service'
import { translate } from '@/language'
import OrderPageContainer from './components/OrderPageContainer'
import OrderMainInfo from './components/OrderMainInfo'
import OrderBaseInfo from './components/OrderBaseInfo'
import OrderExtraInfo from './components/OrderExtraInfo'
import OrderTips from './components/OrderTips'
import OrderPlaceholder from './components/OrderPlaceholder'

import OrderContext from './OrderContext'
import OrderCancellation from './components/OrderCancellation'
import ChoosePayment from './components/ChoosePayment'
import './index.less'
function Order() {
  const { search } = window.location
  const query = parse((search || '').replace(/^\?/, '')) || {}
  const { id, device, callBack, to, price } = query
  const [orderInfo, setOrderInfo] = useState({})
  const isOrderCancelledRef = useRef(false)
  const idRef = useRef(id)
  const timerRef = useRef()
  const [isDone, setIsDone] = useState(false)
  const [noCallBack, setNoCallBack] = useState(false)

  const {
    submitNo,
    payName,
    callBackUrl,
    payIcon,
  } = orderInfo
  const done = (status) => {
    setIsDone(true)
    clearTimeout(timerRef.current)
    if (callBackUrl) {
      setTimeout(() => {
        window.location.replace(callBackUrl)
      }, 100)
    } else {
      setNoCallBack(true)
    }
  }

  const fetchDetail = async () => {
    const id = idRef.current
    if (id) {
      const res = await fetchOrderDetail({ id })
      const { data, message } = res || {}
      if (data) {
        setOrderInfo((prevState) => ({ ...prevState, ...data }))
        return data
      }
      else {
        Message.error(message)
      }
    }
    return Promise.reject(new Error(translate().orderFail))
  }

  const handleError = (error) => {
    console.log(error)
    const { message } = error || {}
    if (String(message).toLowerCase().includes('network')) {
      Message.error(translate().networkError)
    } else if (String(message).toLowerCase().includes('timeout')) {
      done('CANCEL')
    } else {
      done('ERROR')
    }
  }

  const autoFetchDetail = () => {
    clearTimeout(timerRef.current)
    const next = () => {
      timerRef.current = setTimeout(() => {
        autoFetchDetail()
      }, 3000)
    }
    fetchDetail().then((res) => {
      if (window && window.document) {
        document.title = translate().webtitle
      }
      const { orderStatus, id } = res || {}
      idRef.current = id
      if (orderStatus === 'ORDER_STATUS_COMPLETE') {
        Message.success(translate().orderSuccess, 3, () => done('SUCCESS'))
      }
      else if (
        String(orderStatus).includes('CANCEL') &&
        !isOrderCancelledRef.current
      ) {
        Message.error(translate().orderCancel, 3, () => done('CANCEL'))
        isOrderCancelledRef.current = true
      } else if (
        orderStatus === 'ORDER_STATUS_PAY_EXPIRED' &&
        !isOrderCancelledRef.current
      ) {
        Message.error(translate().orderExpired, 3, () => done('TIMEOUT'))
        isOrderCancelledRef.current = true
      } else {
        next()
      }
    })
      .catch((error) => {
        handleError(error, next)
      })
  }

  useEffect(() => {
    autoFetchDetail();
    return () => {
      clearTimeout(timerRef.current)
    }
  }, [])

  const getContextValue = () => ({
    orderInfo,
    device,
    icon: payIcon,
    to,
    from: callBack,
    refs: { isOrderCancelledRef, idRef },
    fns: { done, handleError },
  })
  const { orderStatus } = orderInfo
  return (
    <div className="container order">
      {/* <PageHeader back={callBack} title={translate().webtitle} className="order-header" /> */}
      {(() => {
        if (orderInfo.id) {
          if (isDone && noCallBack) {
            let status = 'error'
            let title = translate().orderExpired
            if (String(orderStatus).includes('CANCEL')) {
              title = translate().orderCancel
            }
            if (orderStatus === 'ORDER_STATUS_COMPLETE') {
              title = translate().orderSuccess
              status = 'success'
            }
            return (
              <OrderPlaceholder
                visible={isDone}
                title={title}
                status={status}
              />
            )
          }
          if(orderStatus=='ORDER_STATUS_PAYING'){
            return (
              <OrderContext.Provider value={getContextValue()}>
                <OrderPageContainer
                  payName={payName}
                  base={<OrderBaseInfo />}
                  tips={<OrderTips />}
                  extra={
                    <div className='extra'>
                      <p>*LƯU Ý : </p>
                      <div>
                        <p>1.Kiểm tra chính xác tài khoản và họ tên người nhận tiền , mọi trường hơp chuyển tiền nhầm , không đúng tài khoản ở trên sẽ không giải quyết ! </p>
                        <p>2. Nhập đúng nội dung chuyển khoản để giao dịch được cập nhật nhanh nhất .</p>
                        <p>3.Chuyển khoản đúng số tiền của giao dịch . </p>
                        <p>4. Không lưu tài khoản ngân hàng , mỗi lần thanh toán  bạn hãy làm mới kênh nạp tiền và lựa chọn lại , không chuyển vào số tài khoản trước đó bạn đã giao dịch .</p>
                      </div>
                    </div>
                  }
                >
                  <OrderMainInfo />
                </OrderPageContainer>
              </OrderContext.Provider>
            )
          }
        }
        return <OrderPlaceholder visible={noCallBack} />
      })()}
    </div>
  )
}

export default Order
