import React, { useContext, Suspense, useState } from 'react'
// import { parse, stringify } from 'qs'
import QrCode from '@/components/QrCode'
import Feedback from './Feedback'
import GoToStore from './GoToStore'
import Spin from '@/components/Spin'
import CountDown from './CountDown'
import { translate } from '@/language'
import { formantPrice} from '@/utils/index'
import OrderContext from '../../OrderContext'
import ToPhone from './ToPhone'
import NeedFeedback from './NeedFeedback'
import { isMobile } from '@/utils'
import AlipayH5 from './AlipayH5'

const BankCardInfo = React.lazy(() => import('./BankCardInfo'))
const VNPayInfo = React.lazy(() => import('./VNPayInfo'))
function OrderMainInfo() {
  const { orderInfo, icon, imagePathPrefix } = useContext(OrderContext)
  const {
    orderStatus: status,
    payType, payIcon,
    receiptQcode,
    payablePrice,
    imageSrc,
    receiptContent,
    payTimeOut
  } = orderInfo || {}
  const [isFinished, setIsFinished] = useState(false)

  const msg = () => {
    let text = translate().orderExpired
    if (String(status).includes('CANCEL')) {
      text = translate().orderCancel
    }
    if (status === 'ORDER_STATUS_COMPLETE') {
      text = translate().orderSuccess
    }
    return <span>{text}</span>
  }
  const getQrcode = () => {
    if(imageSrc&&imageSrc!=''){
      return (<div style={{ padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
        <img src={imageSrc} style={{width:'256px',height:'256px'}}/>
        <div style={{ fontWeight: "bold", color: "#f1b031", fontSize: 20, textAlign: 'center', marginTop: 10 }}>{formantPrice(payablePrice)} VND</div>
      </div>)
    }
    if (receiptQcode && receiptQcode != '') {
      return (<div style={{ padding: '10px', borderRadius: '5px', marginBottom: '10px' }}>
        <QrCode value={receiptQcode} icon={payIcon} size={200} />
        <div style={{ fontWeight: "bold", color: "#f1b031", fontSize: 20, textAlign: 'center', marginTop: 10 }}>{formantPrice(payablePrice)} VND</div>
      </div>)
    }
  }
  const getContent = () => {
    return (
      <Suspense fallback={<Spin />}>
        <div style={{display:'flex',justifyContent:'center',flexDirection:'column',alignItems:'center',gap:0,marginBottom:12}}>
          <CountDown onTimeout={() => { setIsFinished(true) }} />
          <span style={{text:16}}>{translate().payTimeOut}</span>
        </div>
        {getQrcode()}
        <BankCardInfo orderInfo={orderInfo} />
      </Suspense>
    )
  }

  const isMsgNeedTiShown =
    String(status).includes('CANCEL') ||
    String(status).includes('COMPLETE') ||
    isFinished
    const isPaying = status === 'ORDER_STATUS_PAYING'
  return (
    <div>
      <div className='bank-info'>
        <span>
          {(() => {
            if (!isPaying) {
              if (isMsgNeedTiShown) {
                return msg()
              }
              return (
                <CountDown
                  onTimeout={() => {
                    setIsFinished(true)
                  }}
                />
              )
            }
            if (isMsgNeedTiShown) {
              return msg()
            }
            return getContent()
          })()}
        </span>
      </div>
    </div>
  )
}


export default OrderMainInfo
