export const parseConfig = (configStr) => {
  if (typeof configStr !== 'string') {
    return {}
  }
  let config = {}
  try {
    config = JSON.parse(configStr)
  } catch (error) {
    console.log(error, configStr)
  }
  return config
}

export const isMobile = () =>
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
    navigator.userAgent
  )

export const formantPrice=(price)=>{
  return String(parseInt(price)).replace(/\B(?=(\d{3})+(?!\d))/g,',')
}