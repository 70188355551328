import React from 'react'
import { Button, message } from 'antd'
import { CopyToClipboard } from 'react-copy-to-clipboard'
import { translate } from '@/language'
import './index.less'

function Copy(props) {
  const {
    value,
    name,
    icon,
    msg,
    showCopy,
    text,
    children,
    textStyle = {},
    noBtn,
    btnProps = {},
    ...restProps
  } = props
  const displayText = text || children
  return (
    <div {...restProps} className="copy-item">
      {!noBtn && (
        <div style={{ display: "flex", flexDirection: "column",width:"100%", gap: '4px' }}>
          <span className="copy-item-name">{name}: </span>
        {showCopy ?   <div style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
            <span className="copy-item-text">{displayText}</span>

            <CopyToClipboard
              onCopy={(text, result) => {
                if (result) {
                  message.success(translate().copySuccess)
                }
                else {
                  message.error(translate().copyFail)
                }
              }}
              text={value || children}
            >
              {noBtn ? (
                <>
                  <span className="copy-item-name">{name}: </span>
                  <span
                    style={{
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                      ...textStyle,
                    }}
                  >
                    {displayText}
                  </span>
                </>
              ) : (
                <span data-v-4ce3dfa7="" className="icon-svg"><svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <g id="Component 214">
                    <path id="Vector" fillRule="evenodd" clipRule="evenodd" d="M1.19995 11.4C1.19995 11.8774 1.38959 12.3352 1.72716 12.6728C2.06472 13.0104 2.52256 13.2 2.99995 13.2H4.79995V12H2.99995C2.84082 12 2.68821 11.9368 2.57569 11.8243C2.46317 11.7118 2.39995 11.5591 2.39995 11.4V3.00001C2.39995 2.84088 2.46317 2.68827 2.57569 2.57575C2.68821 2.46323 2.84082 2.40001 2.99995 2.40001H11.4C11.5591 2.40001 11.7117 2.46323 11.8242 2.57575C11.9367 2.68827 12 2.84088 12 3.00001V4.80001H6.59995C6.12256 4.80001 5.66472 4.98965 5.32716 5.32722C4.98959 5.66479 4.79995 6.12262 4.79995 6.60001V15C4.79995 15.4774 4.98959 15.9352 5.32716 16.2728C5.66472 16.6104 6.12256 16.8 6.59995 16.8H15C15.4773 16.8 15.9352 16.6104 16.2727 16.2728C16.6103 15.9352 16.8 15.4774 16.8 15V6.60001C16.8 6.12262 16.6103 5.66479 16.2727 5.32722C15.9352 4.98965 15.4773 4.80001 15 4.80001H13.2V3.00001C13.2 2.52262 13.0103 2.06479 12.6727 1.72722C12.3352 1.38965 11.8773 1.20001 11.4 1.20001H2.99995C2.52256 1.20001 2.06472 1.38965 1.72716 1.72722C1.38959 2.06479 1.19995 2.52262 1.19995 3.00001V11.4ZM5.99995 6.60001C5.99995 6.44088 6.06317 6.28827 6.17569 6.17575C6.28821 6.06323 6.44082 6.00001 6.59995 6.00001H15C15.1591 6.00001 15.3117 6.06323 15.4242 6.17575C15.5367 6.28827 15.6 6.44088 15.6 6.60001V15C15.6 15.1591 15.5367 15.3118 15.4242 15.4243C15.3117 15.5368 15.1591 15.6 15 15.6H6.59995C6.44082 15.6 6.28821 15.5368 6.17569 15.4243C6.06317 15.3118 5.99995 15.1591 5.99995 15V6.60001Z" fill="#55AAF0"></path>
                  </g>
                </svg></span> 
              )}
            </CopyToClipboard>

          </div>: 
          
          <span>
            {text}
          </span>
          }
        </div>
      )}
      {/* <CopyToClipboard
        onCopy={(text, result) => {
          if (result) {
            message.success(translate().copySuccess)
          }
          else {
            message.error(translate().copyFail)
          }
        }}
        text={value || children}
      >
        {noBtn ? (
          <>
            <span className="copy-item-name">{name}: </span>
            <span
              style={{
                whiteSpace: 'nowrap',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                ...textStyle,
              }}
            >
              {displayText}
            </span>
          </>
        ) : (
          <span className="copy-item-btn">
            <Button icon={icon}  {...btnProps}>
              {translate().copy}
            </Button>
          </span>
        )}
      </CopyToClipboard> */}
    </div>
  )
}

export default Copy
