import React from 'react'

function OrderPageContainer({ payName, extra, children, base, tips, action }) {
  return (
    <div className="order-info">
      <div className="order-info-title">LUCKY PAY</div>
      <div className="order-info-content">
        <div className="order-info-content-qr">{children}</div>
        <div className="order-cancel">{action}</div>
        {extra}
      </div>
    </div>
  )
}

export default OrderPageContainer
