export const translate = () => {
    return vi
  }


  const vi ={ 
    webtitle:'Quầy thanh toán',
    success:'Thành công',
    fail:'Thất bại',
    networkError:'Lỗi mạng khi cố làm mới trang',
    orderSuccess:'Đơn hàng đã hoàn thành',
    orderCancel:'Đơn hàng bị hủy',
    orderExpired:'Đơn hàng đã hết thời gian',
    orderFail:'Không nhận được đơn hàng và đặt hàng lại',
    payablePrice:'Số tiền thanh toán',
    payTimeOut:'Thời gian thanh toán',
    orderCode:'Số đơn hàng',
    copy:'Sao chép',
    copySuccess:'Đã sao chép thành công',
    copyFail:'Sao chép không thành công',
    return:'Trở lại',
    orderTip1:'Không thanh toán với số tiền',
    orderTip2:',nếu không số dư sẽ không tự động tăng lên.',
    bankName:'Tên ngân hàng',
    bankBranch:'Chi nhánh mở tài khoản',
    receiptName:'Tên người nhận thanh toán',
    receiptAccount:'Số tài khoản thanh toán',
    orderRemark:'Mã nhận biết đơn hàng',
    orderExtra:`   <p>*LƯU Ý : </p>
                      <div>
                        <p>1.Kiểm tra chính xác tài khoản và họ tên người nhận tiền , mọi trường hơp chuyển tiền nhầm , không đúng tài khoản ở trên sẽ không giải quyết ! </p>
                        <p>2. Nhập đúng nội dung chuyển khoản để giao dịch được cập nhật nhanh nhất .</p>
                        <p>3.Chuyển khoản đúng số tiền của giao dịch . </p>
                        <p>4. Không lưu tài khoản ngân hàng , mỗi lần thanh toán  bạn hãy làm mới kênh nạp tiền và lựa chọn lại , không chuyển vào số tài khoản trước đó bạn đã giao dịch .</p>
                    </div>`
  }
  const cn ={ 
    webtitle:'收银台',
    success:'成功',
    fail:'失败',
    networkError:'网络错误尝试刷新页面',
    orderSuccess:'订单已完成',
    orderCancel:'订单已取消',
    orderExpired:'订单已超时',
    orderFail:'获取订单失败重新下单',
    payablePrice:'付款金额',
    payTimeOut:'到期时间',
    orderCode:'订单号',
    copy:'复制',
    copySuccess:'复制成功',
    copyFail:'复制失败',
    return:'返回',
    orderTip1:'勿必按金额',
    orderTip2:'付款，否则余额不会自动增加，请勿多次支付！',
    bankName:'银行名称',
    bankBranch:'开户支行',
    receiptName:'收款姓名',
    receiptAccount:'收款账号',
    orderRemark:'订单识别码',
    orderExtra:` 订单提示`,
  }